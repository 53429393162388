import React from "react"
import Layout from '../components/layout'
import HTML from "../components/HTML"
import Blog from "../components/blogPage/Blog"

const Blogposts = (props) => {
const { pageContext } = props
const { pageContent, title } = pageContext

let components = []
console.log('Page SEO', pageContent.SEO)
if(pageContent.hasOwnProperty("ArrayRawHTML")) {
const { arrayRawHTML } = pageContent.ArrayRawHTML
const rawComponents = arrayRawHTML.map(element => {  
return <HTML data={{ 
order: Number(element.order),
visible: element.visible,
rawHTML: element.rawHtml
}} key={"HTML"} />
})
components.push(...rawComponents)
}

let sortedComponents = components.sort((a, b) =>
parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
) // sorts the components based on json data
// filters out the components that are not visible
let visibleComponents = sortedComponents.filter(component => {
return component.props.data.visible === true
})


return (
<Layout seo={pageContent.SEO}> 
<Blog data={pageContent.blogposts} title={title}/>
{visibleComponents}
</Layout>
)
}

export default Blogposts;